@tailwind base;
@tailwind components;
@tailwind utilities;

@import '~antd/dist/antd.css';
@import './custom.css';
@import './antd.css';

html,
body,
#root {
  @apply flex grow flex-col;
}

body {
  @apply m-0 bg-black font-poppins text-[16px] font-normal text-white;
}

*,
::before,
::after {
  @apply box-border border-0 border-solid border-current;
}

:root {
  --wkit-accent-hs: 210, 100%;
  --wkit-on-accent-rgb: 255, 255, 255;
  --wkit-bg-rgb: 0, 0, 0;
  --wkit-on-bg-rgb: 10, 14, 34;
  --wkit-font-family: 'Inter', sans-serif;
  --wkit-font-family-mono: 'IBM Plex Mono', monospace;
  --wkit-font-size-large: 18px;
  --wkit-font-size-medium: 16px;
  --wkit-font-size-small: 14px;
  --wkit-line-height-large: 22px;
  --wkit-line-height-medium: 20px;
  --wkit-line-height-small: 17px;
  --wkit-button-width: 284px;
  --wkit-border-radius: 16px;
}
