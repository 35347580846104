/* Modal */
.ant-modal-content {
  @apply relative rounded-[16px] bg-black2;
}

.ant-modal-body {
  @apply rounded-[16px] p-0;
}

.ant-modal-close {
  @apply right-[24px] top-[24px];
}

.ant-modal-close-x {
  @apply h-auto w-auto leading-none;
}

/* Switch */
.ant-switch {
  @apply !bg-black;
}

.ant-switch-checked {
  @apply !bg-purple;
}

/* message */
.ant-message-custom-content {
  @apply flex items-center;
}

/* Selecter */
.ant-select-selector {
  @apply !rounded-full !border-[2px] !border-purple !bg-transparent font-inter font-bold text-white;
}

.ant-select-dropdown {
  @apply rounded-[10px] !border-[2px] !border-purple !bg-black p-0;
}

.ant-select-item-option {
  @apply !bg-black font-inter !text-white;
}

.ant-select-item-option-active {
  @apply !bg-purple;
}

.ant-select-arrow {
  @apply text-white;
}
