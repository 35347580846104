.linear-bg {
  background: linear-gradient(270deg, #ff592c 0%, #6d6aff 100%);
}

.linear-border {
  background: linear-gradient(104.21deg, #6d6aff 0%, #6ae4ff 100%);
}

.linear-text {
  color: transparent;
  background-image: linear-gradient(104.21deg, #6d6aff 9.6%, #ea43a7 53.5%, #6ae4ff 98.06%);
  background-clip: text;
}

input {
  @apply !text-center;
}

.buy-now-hover:hover .buy-now-btn-text {
  @apply relative text-white opacity-100;
}

.buy-now-hover:hover .img-hover {
  @apply bottom-[10px];
}

.hide-scrollbar {
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  overflow-x: hidden;
  overflow-y: auto;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

/* Popover */
.ant-popover-inner-content {
  @apply p-0;
}

.ant-popover-arrow-content {
  --antd-arrow-background-color: #111315;
}

.ant-popover-inner {
  @apply bg-transparent;
}

.wallets-popover .ant-popover-arrow {
  @apply hidden;
}

.wallets-popover .ant-popover-inner {
  @apply overflow-hidden rounded-[16px] bg-transparent;
}
